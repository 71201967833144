<template>
<div id="artists-playlist" class="artists">
    <div class="m-t-nav">
        <div class="big-m-t">
            <Playlist
            :playlist-position="0" 
            :playlist="profilesWorld"
            :listType="'profiles'"
            :editButtons="false"
            :title="false"
            :subTitle="subTitle"
            :key="reloadComponent"
            :view="'list'"
            />
        </div>
    </div>
</div>
</template>

<script>
import { rtdb } from '@/db.js'
import { mapGetters } from "vuex"
import Playlist from '@/components/playlists/Playlist.vue'

export default {
  name: 'ArtistsPlaylist',
  components: {
    Playlist
  },
  data() {
	  return {
        profilesWorld: {
          users: null,
        },
        subTitle: null,
        reloadComponent: false
	  }
  },
  created () {
    // fetch the data when the view is created and the data is
    // already being observed
    this.getProfilesWorld()
  },
  watch: {
    // call again the method if the route changes
    '$route': 'getProfilesWorld'
    // '$route' (to, from) {
    //   if(to !== from ) {
    //     this.getProfilesWorld();
    //   }
    // }
  },
  computed: {
    ...mapGetters([
      'getUser',
      'getUserSettings',
    ])
  },
  methods:{
    getProfilesWorld() {
        const urlId = this.$route.params.id
        const nameCapitalized = urlId.charAt(0).toUpperCase() + urlId.slice(1)
        // this.subTitle = nameCapitalized
        rtdb.ref(`rankLocations/rankProfiles/allUsers/${nameCapitalized}`).on('value', (snapshot) => {
            this.profilesWorld.users = snapshot.val()
            this.subTitle = snapshot.val().length + ' artists'
            this.reloadComponent = !this.reloadComponent //This is because the new profilesWorld.users value doesn't reload the component
        })
    },
  }
  
}
</script>
<style lang="scss" scoped>
.artists {
    // min-height: 100vh;
}
</style>